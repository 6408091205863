import PopUp from "../components/Sections/PopUp";
import AgeGate from "../components/AgeGate";
import React, {useState} from "react";

const FormPage = () => {
    const [isLegalAge, setIsLegalAge] = useState(false);
    return (
        <>
            <AgeGate setIsLegalAge={setIsLegalAge}/>
            <PopUp isLegalAge={isLegalAge} />
        </>
    )
}

export default FormPage;
